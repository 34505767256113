import fetch from 'auth/GenericFetchInterceptor'

const CalendarService = {}


/*CalendarService.getUserPuntiVendita = function (path) {
    return fetch({
        url: `/api/users?pagination[limit]=-1&populate[binding_roles][populate][0]=store${path}&populate=role`,
        method: 'GET'
    })
}*/
CalendarService.getUserPuntiVendita = function () {
    return fetch({
        url: `/api/users?pagination[limit]=-1&populate[binding_roles][populate][0]=store&populate=role`,
        method: 'GET'
    })
}

CalendarService.getEvents = function (puntivendita, start, end) {

    const queryStringArray = puntivendita.map(store => `&filters[storeLabel]=${store.id}`);
    return fetch({
        url: `/api/calendars?pagination[limit]=-1&filters[start][$gte]=${start}&filters[end][$lte]=${end}${queryStringArray.join('')}`,
        method: 'GET'
    })
    /*return fetch({
        url: `/api/calendars?pagination[limit]=-1&populate[patient][fields]=nome&populate[store][fields]=name&populate[operatore][fields]=color${queryStringArray.join('')}&filters[start][$gte]=${start}&filters[end][$lte]=${end}`,
        method: 'GET'
    })*/
}
CalendarService.getReport = function (puntivendita, start,end) {

    const queryStringArray = puntivendita.map(store => `&filters[storeLabel]=${store.id}`);
    return fetch({
        url: `/api/calendars?pagination[limit]=-1&filters[start][$gte]=${start}&filters[end][$lte]=${end}&filters[stato]=APPUNTAMENTO CONFERMATO&filters[tipo]=appuntamento&populate[patient][populate][0]=visits&populate[patient][populate][1]=sales_patients${queryStringArray.join('')}`,
        method: 'GET'
    })

}
CalendarService.addEvent = function (body) {

    return fetch({
        url: `/api/calendars`,
        method: 'POST',
        data: body
    })
}
CalendarService.editEvent = function (id, body) {

    return fetch({
        url: `/api/calendars/${id}`,
        method: 'PUT',
        data: body
    })
}
CalendarService.deleteEvent = function (id) {

    return fetch({
        url: `/api/calendars/${id}`,
        method: 'DELETE',
    })
}
export default CalendarService;