const dev = {
    API_ENDPOINT_URL: 'https://server.maffle.it/audiomedical/sviluppo'
};
const coll = {
    API_ENDPOINT_URL: 'https://server.maffle.it/audiomedical/collaudo'
};
const prod = {
    /*'https://ec2.maffle.it/audiomedical/produzione'*/
    API_ENDPOINT_URL: 'https://ec2.maffle.it/audiomedical/produzione'
};

const getEnv = () => {
    switch (process.env.REACT_APP_ENV?.trim()) {
        case 'sviluppo':
            return dev
        case 'collaudo':
            return coll
        case 'produzione':
            return prod
        default:
            return {
                API_ENDPOINT_URL: 'http://localhost:1338'
            };
    }
}

export const env = getEnv()